import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import SplineArea from "UsePages/Dashboard/apex/SplineArea";
import { showMessage } from "../../Utils/global"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import * as dashboardApi from "../../apis/dashboardApi";
import { useSelector } from 'react-redux';
import {formatMoney} from "../../constants/function"
import { isRoleIn, isUserInGroup } from './../../Utils/SecurityUtils';

function Dashboard(props) {
  const selectedShop = useSelector(state => state.SelectedShop);
  const [dataReport, setDataReport] = useState({});

  const isAdmin = isUserInGroup(['Admin']);

  useEffect(() => {
    getParameter();
  }, [selectedShop])

  const getParameter = () => {
    if (isAdmin)
      dashboardApi.getParameter(selectedShop, getDataSuccess, showMessage)
  }
  
  const getDataSuccess = (res) => {
    setDataReport(res.data.data);
  }
  
  return (
    <div className="page-content">
      <MetaTags>
        <title>Quản lý kho tita.art</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title={props.t("Dashboards")}
          breadcrumbItem={props.t("Dashboard")}
        />
        <Row>
          <Col xl="12">
            <Row>
              {/* Reports Render */}
              <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            Số lượng đơn hàng
                          </p>
                          <h4 className="mb-0">{formatMoney(dataReport?.total_bill)}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className="bx bx-copy-alt font-size-24"
                            />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                          Doanh số
                          </p>
                          <h4 className="mb-0">{formatMoney(dataReport?.total_amount)}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className="bx bx-archive-in font-size-24"
                            />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                          Doanh số trung bình
                          </p>
                          <h4 className="mb-0">{formatMoney(dataReport?.average)}</h4>
                        </div>
                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                          <span className="avatar-title">
                            <i
                              className="bx bx-purchase-tag-alt font-size-24"
                            />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
            </Row>

            <Card>
              <CardBody>
                <SplineArea />
              </CardBody>
            </Card>
          </Col>
        </Row>
        </Container>
    </div>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default (withTranslation()(Dashboard))

