import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

LinkButton.propTypes = {
  show       : PropTypes.bool,
  link       : PropTypes.string,
  buttonText : PropTypes.string,
  buttonClass: PropTypes.string,
  iconClass  : PropTypes.string,
  color      : PropTypes.string,
}

function LinkButton(props) {
  return (
    <>
      {
        (props.show) ?
          <Link to={props.link}>
            <Button color={props.color ?? 'primary'} className={props.buttonClass ?? 'px-3 rounded-pill'}>
              <i className={props.iconClass ?? 'fas fa-plus mr-2'}></i> {props.buttonText}
            </Button>
          </Link>
        : null
      }
    </>
  );
}

export default LinkButton
