import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

class PageTitle extends Component {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs="12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">{this.props.title}</h4>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

PageTitle.propTypes = {
  title: PropTypes.string
}

export default PageTitle
