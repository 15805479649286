import React, { useState, useEffect } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from 'react-meta-tags';

import * as reportsApi from '../../apis/reportsApi';
import * as categoryApi from '../../apis/categoryApi';
import * as warehouseApi from '../../apis/wareHouseApi';
import {downloadFile} from "../../constants/function";
import PageTitle from "../../components/Common/PageTitle"
import FilterText from "./FilterComponent/FilterText";
import FilterCompany from "./FilterComponent/FilterCompany"
import FilterFromToDate from "./FilterComponent/FilterFromToDate"
import ReportBody from "./ReportPartials/ReportBody";
import ReportButton from "./ReportPartials/ReportButton";
import ReportButtonContainer from "./ReportPartials/ReportButtonContainer";
import FilterSelectMultipleItems from "./FilterComponent/FilterSelectMultipleItems";


function BcKhoNhapXuatTon() {

  const [dataHead, setDataHead] = useState([]);
  const [dataBody, setDataBody] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedCategories, setSelectedCategories] = useState();
  const [productCode, setProductCode] = useState();
  const [selectedWarehouses, setSelectedWarehouses] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [items, setItems] = useState();
  const [warehouses, setWarehouses] = useState();

  const handleChangeCompany = (value) => {
    if (value != selectedCompany) {
      setSelectedCompany(value);
    }
  };

  const handleChangeCategories = (value) => {
    setSelectedCategories(value);
  };

  const handleChangeProductCode = (value) => {
    setProductCode(value);
  };

  const handleChangeWarehouses = (value) => {
    setSelectedWarehouses(value);
  };

  const handleChangeFromDate = (value) => {
    setFromDate(value);
  };

  const handleChangeToDate = (value) => {
    setToDate(value);
  };
    
  const renderTable = () => {
    if (selectedCompany === undefined) { alert("Chọn hệ thống cửa hàng"); return; }

    const params = [
        { name: "company_id", value: selectedCompany },
        { name: "code", value: productCode },
        { name: "from_date", value: fromDate },
        { name: "to_date", value: toDate },
        { name: "cat_ids", value: selectedCategories },
        { name: "warehouses", value: selectedWarehouses },
    ];
    reportsApi.getDataReportNhapXuatTonV2(params, getDataView, showErr);
  }

  const getDataView = (res) => {
    setDataHead(res?.data?.data?.headers);
    setDataBody(res?.data?.data?.details);
  }

  const showErr = (res) => {
    alert(res.message);
  }  

  const downloadReport = () => {
    if (selectedCompany === undefined) { alert("Chọn hệ thống cửa hàng"); return; }

    const params = [
        { name: "company_id", value: selectedCompany },
        { name: "code", value: productCode },
        { name: "from_date", value: fromDate },
        { name: "to_date", value: toDate },
        { name: "cat_ids", value: selectedCategories },
        { name: "warehouses", value: selectedWarehouses },
    ];
    reportsApi.downloadReportNhapXuatTonV2(params, downloadExcel, showErr);
  }

  const downloadExcel = (res) => {
      downloadFile(res.data.data);
  }

  useEffect(() => {
      getCategories();
      getWarehouses();
  }, [selectedCompany]);

  useEffect(() => {
      console.log('warehouses = ', selectedWarehouses)
  }, [selectedWarehouses]);

  const getCategories = () => {
      categoryApi.getAllCategory(selectedCompany, parseData, showErr)
  }

  const parseData = (res) => {
    let listItem = res.data.data.map(item => {
        return {
            label: item['category_name'],
            value: item['category_id']
        }
    })
    setItems(listItem);
  }

  const getWarehouses = () => {
    warehouseApi.getAll(selectedCompany, parseWarehouse, showErr)
  }

  const parseWarehouse = (res) => {
    let whs = res.data.data.map(item => {
        return {
            label: item['warehouse_name'],
            value: item['warehouse_code']
        }
    })
    setWarehouses(whs);
  }

  return (
    <React.Fragment>
      <div className="page-content">
      <MetaTags>
          <title>Nhập xuất tồn</title>
        </MetaTags>
        <Container fluid>
          <PageTitle title="Nhập xuất tồn" />
            <Card>
              <CardBody>
                <FilterCompany
                  onChange={handleChangeCompany} />

                <FilterSelectMultipleItems
                  label="Nhóm sản phẩm"
                  items={items}
                  onChange={handleChangeCategories} />

                <FilterText
                  label="Mã sản phẩm"
                  onChange={handleChangeProductCode} />

                <FilterSelectMultipleItems
                  label="Kho"
                  items={warehouses}
                  onChange={handleChangeWarehouses} />

                <FilterFromToDate
                  onChangeFromDate={handleChangeFromDate}
                  onChangeToDate={handleChangeToDate} />
                
                <ReportButtonContainer>
                  <ReportButton
                    label='Xem báo cáo'
                    onClick={renderTable} />

                  <ReportButton
                    label='Download báo cáo'
                    onClick={downloadReport} />
                </ReportButtonContainer>
                
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <ReportBody 
                  dataHead={dataHead}
                  dataBody={dataBody} />
              </CardBody>
            </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BcKhoNhapXuatTon
