import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { showMessage } from "Utils/global";

UploadButton.propTypes = {
  uploadCallback: PropTypes.func,
  succesCallback: PropTypes.func,
  show: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonClass: PropTypes.string,
  color: PropTypes.string,
  iconClass: PropTypes.string,
  fileTypes: PropTypes.string,
  uploadKey: PropTypes.string,
  inputId: PropTypes.string,
  additionalParams: PropTypes.object,
}

function UploadButton(props) {

  const handleClick = () => {
    document.getElementById(props.inputId).click();
  };

  const onUpload = async (e) => {
    const file = e.target.files;
    if (file) {
      let formData = new FormData();
      
      Object.entries(props.additionalParams).map(([key, value]) => {
        formData.append(key, value);
      })

      for (const key of Object.keys(file)) {
        formData.append(props.uploadKey, file[key])
      }

      props.uploadCallback(formData, props.succesCallback, showMessage)
    }
  };

  return (
    <>
      {
        (props.show) ?
          <>
            <Button color={props.color} className={props.buttonClass} onClick={handleClick}>
              <i className={props.iconClass}></i> {props.buttonText}
            </Button>
            <input
              type="file"
              id={props.inputId}
              multiple
              style={{ display: 'none' }}
              onChange={onUpload}
              accept={props.fileTypes} //".jpg, .jpeg, .png, .webp"
            />
          </>
          : null
      }
    </>
  );
}

export default UploadButton
