import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-drawer/lib/react-drawer.css";
import 'react-toastify/dist/ReactToastify.css';
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import { Link, withRouter, useHistory } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import megamenuImg from "../../assets/images/megamenu-img.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

import logo from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/logo-light.svg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";
import { changeSelectedShop } from '../../store/selectedShop/actions'

import * as shopApi from '../../apis/shopApi';
import { changeFirstLoadToTrue } from './../../store/firstLoad/actions';
import { changeAllShop } from './../../store/allShops/actions';
import { select } from "redux-saga/effects";

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSearch: false,
			open: false,
			position: "right",
			// listShop: [],
		};
		this.toggleMenu = this.toggleMenu.bind(this);
		this.toggleFullscreen = this.toggleFullscreen.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.fetchDataSuccess = this.fetchDataSuccess.bind(this);
		this.fetchDataFail = this.fetchDataFail.bind(this);
		this.renderListShop = this.renderListShop.bind(this);
		this.showSelectedShopName = this.showSelectedShopName.bind(this);
		this.changeSelectedShopLocal = this.changeSelectedShopLocal.bind(this);
		this.handleChangeSelectedShopLocal = this.handleChangeSelectedShopLocal.bind(this);

	}
	/**
	 * Toggle sidebar
	 */
	
	toggleMenu() {
		this.props.toggleMenuCallback();
	}

	/**
	 * Toggles the sidebar
	 */
	toggleRightbar() {
		this.props.toggleRightSidebar();
	}

	toggleFullscreen() {
		if (
			!document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
			!document.webkitFullscreenElement
		) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}
	componentDidMount() {
		if (!this.props.firstLoad) {
			this.fetchData();
			this.props.changeFirstLoadToTrue()
		}
	}
	fetchData() {
		shopApi.getAllShop(this.fetchDataSuccess, this.fetchDataFail)
	}
	fetchDataSuccess(res) {
		this.props.changeAllShop(res.data.data)
		let selectedShop = localStorage.getItem('selectedShop');
    console.log('fetch shops = ', res.data.data)
    console.log('fetch shops selectedShop from local storage = ', selectedShop);

		if ((selectedShop === null) || (selectedShop === undefined)){
			selectedShop = res.data.data[0].company_id;
		} else {
			const foundShop = res.data.data.filter(shop => shop.company_id === selectedShop);

			if (!foundShop) {
				selectedShop = res.data.data[0].company_id;
			}
		} 

    console.log('fetch shops set selectedShop to redux store = ', selectedShop);
		this.changeSelectedShopLocal(parseInt(selectedShop));
	}
	fetchDataFail(res) {
		alert("Lỗi xảy ra")
	}
	renderListShop() {
		if (this.props.allShops.length == 0) return (
			<DropdownItem tag="a">
			</DropdownItem>
		);
		return this.props.allShops.map((v, i) => {
			return (
				<DropdownItem key={i} onClick={e => { this.handleChangeSelectedShopLocal(v.company_id) }}>
					{this.props.t(v.company_name)}
					{/* <Link to="/dashboard" style={{color: 'black'}}>
						{this.props.t(v.company_name)}
					</Link> */}
				</DropdownItem>
			)
		})
	}
	handleChangeSelectedShopLocal(idShop) {
		let testExp = /.*\/create$/i
		
		if (testExp.test(this.props.location.pathname))
			this.props.history.goBack()

		localStorage.setItem("selectedShop", idShop)
		this.props.changeSelectedShop(idShop);
	}

	changeSelectedShopLocal(idShop) {
		// this.props.history.push("/dashboard");
		this.props.changeSelectedShop(idShop);
	}
	showSelectedShopName() {
		if (this.props.allShops.length == 0) return "";
		let result = ""
		this.props.allShops.forEach((v, i) => {
			if (v.company_id == this.props.selectedShop) result = v.company_name;
		})
		return result;
	}

	render() {
		return (
			<React.Fragment>
				<header id="page-topbar">
					<div className="navbar-header">
						<div className="d-flex">
							<div className="navbar-brand-box d-lg-none d-md-block">
								<Link to="/" className="logo logo-dark">
									<span className="logo-sm">
										TITA.ART
									</span>
								</Link>

								<Link to="/" className="logo logo-light">
									<span className="logo-sm">
                  TITA.ART
									</span>
								</Link>
							</div>

							<button
								type="button"
								onClick={this.toggleMenu}
								className="btn btn-sm px-3 font-size-16 header-item"
								id="vertical-menu-btn"
							>
								<i className="fa fa-fw fa-bars"></i>
							</button>

							<Dropdown
								className="dropdown d-lg-block ms-2"
								isOpen={this.state.megaMenuDrp}
								toggle={() => {
									this.setState({ megaMenuDrp: !this.state.megaMenuDrp });
								}}
							>
								<DropdownToggle className="btn header-item" caret tag="button">
									{" "}
									{this.props.t(this.showSelectedShopName())}{" "}
									<i className="mdi mdi-chevron-down"></i>
								</DropdownToggle>
								<DropdownMenu className="dropdown-menu p-0">
									{this.renderListShop()}
								</DropdownMenu>
							</Dropdown>
						</div>
						<div className="d-flex">
							<div className="dropdown d-inline-block d-lg-none ms-2">
								<button
									onClick={() => {
										this.setState({ isSearch: !this.state.isSearch });
									}}
									type="button"
									className="btn header-item noti-icon"
									id="page-header-search-dropdown"
								>
									<i className="mdi mdi-magnify"></i>
								</button>
								<div
									className={
										this.state.isSearch
											? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
											: "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
									}
									aria-labelledby="page-header-search-dropdown"
								>
									<form className="p-3">
										<div className="form-group m-0">
											<div className="input-group">
												<input
													type="text"
													className="form-control"
													placeholder="Search ..."
													aria-label="Recipient's username"
												/>
												<div className="input-group-append">
													<button className="btn btn-primary" type="submit">
														<i className="mdi mdi-magnify"></i>
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>

							<NotificationDropdown />
							<ProfileMenu />

						</div>
					</div>
				</header>
			</React.Fragment>
		);
	}
}

Header.propTypes = {
	t: PropTypes.any,
	history: PropTypes.any,
	toggleMenuCallback: PropTypes.any,
	showRightSidebar: PropTypes.any,
	toggleRightSidebar: PropTypes.func,
	changeSelectedShop: PropTypes.func,
	selectedShop: PropTypes.number,
	firstLoad: PropTypes.bool,
	changeFirstLoadToTrue: PropTypes.func,
	changeAllShop: PropTypes.func,
	allShops: PropTypes.array,
	location: PropTypes.object,
};

const mapStatetoProps = state => {
	const { layoutType, showRightSidebar } = state.Layout;
	const selectedShop = state.SelectedShop;
	const firstLoad = state.firstLoad;
	const allShops = state.allShops;

	return { layoutType, showRightSidebar, selectedShop,firstLoad,allShops };
};

export default connect(mapStatetoProps, { toggleRightSidebar, changeSelectedShop,changeFirstLoadToTrue,changeAllShop })(
	withTranslation()(withRouter(Header))
);
