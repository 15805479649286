import React, { useState } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from 'react-meta-tags';

import * as reportsApi from '../../apis/reportsApi';
import {downloadFile} from "../../constants/function";
import PageTitle from "../../components/Common/PageTitle"
import FilterCompany from "./FilterComponent/FilterCompany"
import FilterFromToDate from "./FilterComponent/FilterFromToDate"
import ReportButton from "./ReportPartials/ReportButton";
import ReportButtonContainer from "./ReportPartials/ReportButtonContainer";


function BcBanHang() {

  const [selectedCompany, setSelectedCompany] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const handleChangeCompany = (value) => {
    setSelectedCompany(value);
  };

  const handleChangeFromDate = (value) => {
    setFromDate(value);
  };

  const handleChangeToDate = (value) => {
    setToDate(value);
  };
    
  const downloadReport = () => {
    if (selectedCompany === undefined) {
      alert("Chọn hệ thống cửa hàng");
      return;
    }

    const params = [
        { name: "company_id", value: selectedCompany },
        { name: "from_date", value: fromDate },
        { name: "to_date", value: toDate }
    ];
    reportsApi.downloadReportBcBanHang(params, downloadExcel, showErr);
  }

  const downloadExcel = (res) => {
      downloadFile(res.data.data);
  }

  const showErr = (res) => {
    alert(getMessage(res.message));
  }

  return (
    <React.Fragment>
      <div className="page-content">
      <MetaTags>
          <title>BC bán hàng</title>
        </MetaTags>
        <Container fluid>
          <PageTitle title="Báo cáo bán hàng" />
            <Card>
              <CardBody>
                <FilterCompany
                  onChange={handleChangeCompany} />

                <FilterFromToDate
                  onChangeFromDate={handleChangeFromDate}
                  onChangeToDate={handleChangeToDate} />
                
                <ReportButtonContainer>
                  <ReportButton
                    label='Download báo cáo'
                    onClick={downloadReport} />
                </ReportButtonContainer>
                
              </CardBody>
            </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BcBanHang
