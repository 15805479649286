import React, { useState } from "react"
import { Container, Card, CardBody } from "reactstrap"
import MetaTags from 'react-meta-tags';

import * as reportsApi from './../../apis/reportsApi';
import PageTitle from "../../components/Common/PageTitle"
import FilterCompanies from "./FilterComponent/FilterCompanies"
import FilterFromToDate from "./FilterComponent/FilterFromToDate"
import ReportBody from "./ReportPartials/ReportBody";
import ReportButton from "./ReportPartials/ReportButton";
import ReportButtonContainer from "./ReportPartials/ReportButtonContainer";


function BcDoanhSoHeThong() {

  const [dataHead, setDataHead] = useState([]);
  const [dataBody, setDataBody] = useState([]);

  const [selectedCompanies, setSelectedCompanies] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const handleChangeCompanies = (value) => {
    setSelectedCompanies(value);
  };

  const handleChangeFromDate = (value) => {
    setFromDate(value);
  };

  const handleChangeToDate = (value) => {
    setToDate(value);
  };
    
  const renderTable = () => {
    if (selectedCompanies !== undefined) {
      const params = [
          { name: "company_ids", value: selectedCompanies },
          { name: "from_date", value: fromDate },
          { name: "to_date", value: toDate }
      ];
      reportsApi.getDataReportsDsht(params, getDataView, showErr);
    } else {
      alert("Chọn hệ thống cửa hàng");
    }
  }

  const getDataView = (res) => {
    setDataHead(res?.data?.data?.headers);
    setDataBody(res?.data?.data?.details);
  }

  const showErr = (res) => {
    alert(res.message);
  }

  return (
    <React.Fragment>
      <div className="page-content">
      <MetaTags>
          <title>DS hệ thống</title>
        </MetaTags>
        <Container fluid>
          <PageTitle title="Doanh số hệ thống" />
            <Card>
              <CardBody>
                <FilterCompanies
                  onChange={handleChangeCompanies} />

                <FilterFromToDate
                  onChangeFromDate={handleChangeFromDate}
                  onChangeToDate={handleChangeToDate} />
                
                <ReportButtonContainer>
                  <ReportButton
                    label='Xem báo cáo'
                    onClick={renderTable} />
                </ReportButtonContainer>
                
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <ReportBody 
                  dataHead={dataHead}
                  dataBody={dataBody} />
              </CardBody>
            </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BcDoanhSoHeThong
